<template>

  <div>

    <b-card
      no-body
      class="mb-0 px-2"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="400"
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refTablaBuzonDenuncias"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :no-provider-filtering="true"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :height="myProvider.length < 5 ? '200px' : null"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ from + data.index }}
        </template>
        <template #cell(accion)="data">
          <b-button
            size="sm"
            variant="outline-danger"
            @click="cambiarComoVisto(data.item)"
          >
            <span class="text-nowrap">pasar a denuncias</span>
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner, BFormSelect,
  BPagination, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import buzonStoreModule from './buzonStoreModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BPagination,
    BButton,
    BFormSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      filter: '',
      filterOn: [],

    }
  },
  methods: {
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaBuzonDenuncias.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('buzon-store-module/getBuzonDenuncias', { queryParams: ctx })

      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    cambiarComoVisto(item) {
      const DATA = {
        formulario_id: item.formulario_id,
        usuario_anonimo: item.usuario_anonimo,
      }
      store.dispatch('buzon-store-module/pasarAdenuncias', DATA)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registro pasado a denuncias.',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.actualizarTabla()
        })
    },

  },
  setup() {
    const BUZON_APP_STORE_MODULE_NAME = 'buzon-store-module'

    // Register module
    if (!store.hasModule(BUZON_APP_STORE_MODULE_NAME)) store.registerModule(BUZON_APP_STORE_MODULE_NAME, buzonStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUZON_APP_STORE_MODULE_NAME)) store.unregisterModule(BUZON_APP_STORE_MODULE_NAME)
    })

    const tableColumns = [

      { key: 'index', label: 'N°' },
      { key: 'nombres', label: 'nombres', sortable: true },
      { key: 'dni', label: 'dni', sortable: true },
      { key: 'email', label: 'email', sortable: true },
      { key: 'accion', label: 'acción', sortable: true },
    ]

    return {
      buzonStoreModule,
      tableColumns,
      avatarText,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
